/* eslint-disable */
import * as Types from '../../../../graphql/gql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetNetBenefitQueryVariables = Types.Exact<{
  startDate: Types.Scalars['String'];
  endDate: Types.Scalars['String'];
  propertyId: Types.Scalars['String'];
}>;


export type GetNetBenefitQuery = { __typename?: 'Query', getNetBenefit: Array<{ __typename?: 'NetBenefitResult', current_otb?: number | undefined, current_rate?: number | undefined, group_booked?: number | undefined, stay_date?: string | undefined, total_capacity?: number | undefined, total_fcst?: number | undefined, fcst_rev?: number | undefined } | undefined> };

export type GetRoomsRemainingQueryVariables = Types.Exact<{
  startDate: Types.Scalars['String'];
  endDate: Types.Scalars['String'];
  propertyId: Types.Scalars['String'];
}>;


export type GetRoomsRemainingQuery = { __typename?: 'Query', getRoomsRemaining: Array<{ __typename?: 'RoomType', resort_id?: string | undefined, stay_date?: string | undefined, rt_bed_type?: string | undefined, rt_description?: string | undefined, rt_remaining?: number | undefined, snapshot_date?: string | undefined, property_id?: string | undefined } | undefined> };


export const GetNetBenefitDocument = gql`
    query GetNetBenefit($startDate: String!, $endDate: String!, $propertyId: String!) {
  getNetBenefit(startDate: $startDate, endDate: $endDate, propertyId: $propertyId) {
    current_otb
    current_rate
    group_booked
    stay_date
    total_capacity
    total_fcst
    fcst_rev
  }
}
    `;

/**
 * __useGetNetBenefitQuery__
 *
 * To run a query within a React component, call `useGetNetBenefitQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNetBenefitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNetBenefitQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useGetNetBenefitQuery(baseOptions: Apollo.QueryHookOptions<GetNetBenefitQuery, GetNetBenefitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNetBenefitQuery, GetNetBenefitQueryVariables>(GetNetBenefitDocument, options);
      }
export function useGetNetBenefitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNetBenefitQuery, GetNetBenefitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNetBenefitQuery, GetNetBenefitQueryVariables>(GetNetBenefitDocument, options);
        }
export type GetNetBenefitQueryHookResult = ReturnType<typeof useGetNetBenefitQuery>;
export type GetNetBenefitLazyQueryHookResult = ReturnType<typeof useGetNetBenefitLazyQuery>;
export type GetNetBenefitQueryResult = Apollo.QueryResult<GetNetBenefitQuery, GetNetBenefitQueryVariables>;
export const GetRoomsRemainingDocument = gql`
    query GetRoomsRemaining($startDate: String!, $endDate: String!, $propertyId: String!) {
  getRoomsRemaining(
    startDate: $startDate
    endDate: $endDate
    propertyId: $propertyId
  ) {
    resort_id
    stay_date
    rt_bed_type
    rt_description
    rt_remaining
    snapshot_date
    property_id
  }
}
    `;

/**
 * __useGetRoomsRemainingQuery__
 *
 * To run a query within a React component, call `useGetRoomsRemainingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoomsRemainingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoomsRemainingQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useGetRoomsRemainingQuery(baseOptions: Apollo.QueryHookOptions<GetRoomsRemainingQuery, GetRoomsRemainingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoomsRemainingQuery, GetRoomsRemainingQueryVariables>(GetRoomsRemainingDocument, options);
      }
export function useGetRoomsRemainingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoomsRemainingQuery, GetRoomsRemainingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoomsRemainingQuery, GetRoomsRemainingQueryVariables>(GetRoomsRemainingDocument, options);
        }
export type GetRoomsRemainingQueryHookResult = ReturnType<typeof useGetRoomsRemainingQuery>;
export type GetRoomsRemainingLazyQueryHookResult = ReturnType<typeof useGetRoomsRemainingLazyQuery>;
export type GetRoomsRemainingQueryResult = Apollo.QueryResult<GetRoomsRemainingQuery, GetRoomsRemainingQueryVariables>;
export const namedOperations = {
  Query: {
    GetNetBenefit: 'GetNetBenefit',
    GetRoomsRemaining: 'GetRoomsRemaining'
  }
}