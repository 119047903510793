import pluralize from 'pluralize';

const DEFAULT_SEASON_NAME = 'Default';

export const labels = {
  app: {
    name: 'BestREV 2.0',
    support_email: 'service@bwh.com',
    release_date: '2023-01-03',
    support_button: 'Email Support',
  },
  common: {
    save: 'Save',
    yes: 'Yes',
    no: 'No',
    cancel: 'Cancel',
    confirm: 'Confirm',
    priority: 'Priority',
    none: 'None',
    rec_levels: ['low', 'medium', 'high'],
    delete: 'Delete',
  },
  comp_rates: {
    tooltip: {
      stay_date: 'Stay Date:',
      shop_date: 'Shop Date:',
      rate: 'Rate:',
      room_name: 'Room Name:',
      los: 'LOS:',
      best_flex: 'Best Flex?:',
      rate_changes: 'Rate Changes',
      days_ago: (day: number) => `${day} ${pluralize('day', day)} ago:`,
    },
    messages: {
      missing: 'N/A',
      soldout: 'Sold Out',
      nobar: 'No BstFlx',
      no1p: 'No 1P',
      no2p: 'No 2P',
      noBkfst: 'No Bkfst',
      noRmType: 'No RmType',
      los2: 'LOS2',
      los3: 'LOS3',
    },
  },
  router_prompt_modal: {
    title: 'Unsaved changes exist !!',
    message:
      'You have unsaved changes. Please continue without saving or cancel to stay on this page.',
    btn_confirm_text: 'Continue',
  },
  rate_upload: {
    tooltip_title: 'Upload Selected Rates',
    header: 'Confirm Rate Upload',
    verify_msg:
      'Please verify the following pending rate changes before confirming the upload. You may need to scroll down to view all rates.',
    confirm_btn: 'Upload',
  },
  add_form: {
    required: 'Required',
    start_date_err: '"Start Date" cannot be in the past',
    end_date_err: '"End date" should be greater than or equal to start date',
    required_day_err: 'At least 1 day is required',
    invalid_day_err:
      'Please select a day that falls within the selected date range',
    season_name: 'Season Name',
    start_date: 'Start Date',
    end_date: 'End Date',
    highlight_myview: 'Highlight in My View',
    priority: 'Priority',
    last_updated_by: 'Last updated by',
    on: 'on',
  },
  daypicker: {
    tooltip_msg: 'The chosen date range does not include any',
  },
  autocomplete: {
    no_property_selected: 'No property selected',
    no_property_found: 'No matching properties found',
    placeholder: 'Please login to search',
    searching: 'Searching...',
  },
  header: {
    logo_text: 'BestREV',
    property_search_placeholder: 'Search Property',
    info_popup: {
      header: 'About',
      message: 'Please write all product questions and comments to',
    },
    user_details: {
      default_initials: 'BWH',
      logout: 'Log Out',
      login: 'Log In',
    },
  },
  nav_bar: {
    home: 'Home',
    my_view: 'My View',
    events: 'Events',
    insights: 'Insights',
    rules: 'Rules',
    settings: 'Settings',
    help: 'Help',
    admin: 'Admin',
    groups: 'Groups',
  },
  ask_best_rev: {
    select_option_msg: 'Please select a question from Ask BestREV to see data.',
    title: 'Ask BestREV',
    key1: 'Display all nights when my hotel is projected to sell out',
    key2: 'Display all nights when my hotel is priced below Market Rate',
    key3: 'Display all nights with ADR less than 20% of last year',
    key4: 'Display all nights with 20% or more transient pickup in the last 3 days',
  },
  home: {
    salutation: 'Hello',
    recs_message: (upToDate: boolean) =>
      `You have ${upToDate ? 'new' : 'older'} price recommendations`,
    generated_dates_message: (start: string, end: string) =>
      `generated for ${start} through ${end}`,
    last_updated_message: (updatedAt: string) =>
      `as of ${updatedAt} hotel's local time`,
    alert_messages: {
      warning: 'BWH Rate less than Market Rate with forecasted occupancy > 90%',
      insight: '12% of capacity booked in the last 12 hours',
    },
    night: 'Night',
    nights: 'Nights',
    review: 'Review',
    incr: 'Incr',
    decr: 'Decr',
    revenue: 'Revenue',
    months: [
      'JAN',
      'FEB',
      'MAR',
      'APR',
      'MAY',
      'JUN',
      'JUL',
      'AUG',
      'SEP',
      'OCT',
      'NOV',
      'DEC',
    ],
    rec_statuses: {
      N: 'No Change',
      P: 'Pending',
      S: 'Sent',
      R: 'Review',
      E: 'Error',
    },
    tooltip: {
      filter_rec_status: 'Filter by status',
      remove_filter: 'Remove Filter',
      priority_filter: 'Priority (Click to filter)',
      upload: 'Upload Rate',
      reset: 'Reset Changes',
    },
    bw_rate: 'BWH Rate',
    yoy_rate: 'YOY Rate',
    available_rooms: 'Avail Rooms',
    otb: 'OTB',
    rec_rate: 'Rec Rate',
    market_rate: 'Market Rate',
    rate_to_upload: 'Rate to Upload',
    adr: 'ADR',
    inventory_labels: {
      grp_blocked: 'Group Blocked',
      grp_booked: 'Group Booked',
      out_of_order: 'Out-of-Order',
      transient: 'Transient Booked',
      phy_cap: 'Physical Capacity',
    },
    header_names: {
      level: 'Level',
      otb: 'Curr OTB',
      fcst: 'Forecasted\nRooms to be Sold',
      status: 'Curr Status',
    },
  },
  login: {
    greeting: 'Please login to continue',
  },
  rec_review: {
    chart: {
      room: 'Rooms',
      rates: 'Rates',
      total_fcst: 'Total Forecast',
      total_fcst_incl_group: 'Total Fcst (incl Grp)',
      current_otb: 'Current OTB',
      stay_date: 'Stay Date',
      graph: {
        demand: 'Demand vs Rate',
        inventory: 'Inventory',
      },
      return_to_grid: 'Return to Grid View',
    },
    days: {
      day30: '30 days',
      day60: '60 days',
      day90: '90 days',
      day120: '120 days',
      day365: '365 days',
    },
    weeks: {
      all: 'All Days',
      weekends: 'Weekends',
      weekdays: 'Weekdays',
    },
    dashboard: {
      missing_status_alert:
        'Some or all of the stay dates are missing statuses and may not be filtered correctly',
      warning_header: 'Potential Sellout !!',
      override_cell: {
        old_snapshot: 'Recommendations last updated',
      },
      tooltip: {
        adr: 'Average Daily Rate',
        adr_lyf: 'ADR (Last Year Final)',
        adr_lyst: 'ADR (Last Year Same Time)',
        alert: 'Alert',
        available_rooms: 'Available Rooms',
        bw_rate: 'Best Western Rate',
        con_dmd_curr_rate: 'Constrained Demand @ BWH rate',
        con_dmd_rec_rate: 'Constrained Demand @ Rec rate',
        current_revenue: 'Current Revenue',
        date: 'Stay Date',
        derived_rate: 'Derived Rate',
        dow: 'Day of Week',
        event: 'Event',
        favorite: 'Favorite',
        fcst_lvl_rate: 'OTB / Forecast / Status',
        fcst_occ_pct: 'Forecast Occupancy (Includes Group)',
        fcst_occ_pct_transient: 'Forecast Occupancy (Transient Only)',
        fcst_rev: 'Forecast Revenue',
        final_rec_rate: 'Final Rec Rate',
        group_blocked: 'Group Blocked',
        group_booked: 'Group Booked',
        last_current_rate: 'Previous Current Rate',
        market_rate: 'Market Rate',
        market_rate_lyf: 'Market Rate (Last Year Final)',
        market_rate_lyst: 'Market Rate (Last Year Same Time)',
        occ_pct: 'Occupancy (Includes Group)',
        occ_pct_lyf: 'Occupancy (Last Year Final)',
        occ_pct_lyst: 'Occupancy (Last Year Same Time)',
        ooo: 'Out-of-Order',
        otb: 'On the Books (Includes Group)',
        otb_lyf: 'On the Books (Last Year Final)',
        otb_lyst: 'On the Books (Last Year Same Time)',
        physical_capacity: 'Physical Capacity',
        pkp_day_1: '1-Day Pickup',
        pkp_day_1_ly: '1-Day Pickup (Last Year)',
        pkp_day_3: '3-Day Pickup',
        pkp_day_3_ly: '3-Day Pickup (Last Year)',
        pkp_day_7: '7-Day Pickup',
        pkp_day_7_ly: '7-Day Pickup (Last Year)',
        pkp_doa_ly: 'Pick-up day of arrival last year',
        transient_pickup_day_1: '1-Day Transient Pickup',
        transient_pickup_day_3: '3-Day Transient Pickup',
        transient_pickup_day_7: '7-Day Transient Pickup',
        group_pickup_day_1: '1-Day Group Pickup',
        group_pickup_day_3: '3-Day Group Pickup',
        group_pickup_day_7: '7-Day Group Pickup',
        prev_rec_rate: 'Previous Rec Rate',
        priority: 'Priority',
        rate_to_upload: 'Rate to Upload',
        rate_variance: 'Rate Variance (Rec vs Prev)',
        rec_rate: 'Recommended Rate',
        rec_rate_desc:
          "BestREV2.0 uses its subject hotel's current and historical pricing and demand pattern against its competitors when recommending a price change",
        rec_status: 'Recommendation Status',
        revpar: 'RevPAR',
        revpar_lyf: 'RevPAR (Last Year Final)',
        revpar_lyst: 'RevPAR (Last Year Same Time)',
        total_fcst: 'Total Forecast (Includes Group)',
        transient_cap: 'Transient Capacity',
        transient_fcst: 'Transient Forecast',
        unc_transient_fcst: 'Unconstrained Transient Forecast',
        unc_dmd_curr_rate: 'Unconstrained Demand @ BWH rate',
        yoy_rate_lyf: 'Yoy Rate (Last Year Final)',
        yoy_rate_lyst: 'Yoy Rate (Last Year Same Time)',
      },
      forecast_info: {
        header: (changeType: '' | 'inc' | 'dec', changeInRecRate: number) =>
          changeType
            ? `BestREV recommends a $${Math.round(Math.abs(changeInRecRate))} ${
                changeType === 'inc' ? 'increase' : 'decrease'
              } over the previously recommended rate.`
            : 'BestREV does not recommend a price change over the previously recommended rate !!',
        new_rec_rate: 'New Rec Rate',
        as_of: 'as of',
        prev_rec_rate: 'Previous Rec Rate',
        market_rate_change: 'Change in Market Rate',
        reasons: 'Reason(s)',
        last_hour_pickup: 'Last Hour Pickup',
        pickup_since_12: 'Pickup Since 12:00am',
        expected_pickup: 'Expected Pickup',
        actual_pickup: 'Actual Pickup',
        expected_all_day_pickup: 'Expected All Day Pickup',
        unconstrained: 'unconstrained',
        reason_codes: {
          1: 'More pickup than normal at this time',
          2: 'Less pickup than normal at this time',
          3: 'Increase in rooms remaining to be sold',
          4: 'Decrease in rooms remaining to be sold',
          5: 'Increase in BW Rate',
          6: 'Decrease in BW Rate',
          7: 'Increase in Market Rate',
          8: 'Decrease in Market Rate',
        },
      },
      price_decision: {
        recommended_action: 'Recommended Action',
        inc: 'Increase Rate',
        dec: 'Decrease Rate',
        nc: 'No change to BW Rate',
        pickup: 'Pickup Ratio',
        price: 'Pricing Ratio',
        eval_demand_pattern: 'Evaluation of Pricing & Demand Pattern',
      },
      more_action: {
        deselect_all: 'Deselect All',
        export: 'Export',
        graph: 'Show Graph',
        more_options: 'More Options',
        refresh: 'Reset/Refresh',
        save: 'Save',
        select_all: 'Select All',
        suppress_warnings: 'Suppress Warnings',
        upload: 'Upload Selected',
        warnings_suppressed: 'Warnings Suppressed',
        add_column_to_metric: 'Add to My Metric',
        remove_column_from_metric: 'Remove from My Metrics',
        reset_columns: {
          header: 'Reset Columns',
          message:
            'Are you sure you want to reset column selections to default position?',
          confirm: 'Yes',
          cancel: 'No',
        },
        create_season: 'Create Season',
        create_event: 'Create Event',
        create_mask: 'Create Mask',
        show_season: 'Show Season',
        do_not_autopilot: 'Do Not Autopilot',
        show_past: 'Show Past 7 Days',
        hide_past: 'Hide Past 7 Days',
      },
      bw_rate: 'BWH Rate',
      market_rate: 'Market Rate',
      rec_rate: 'Rec Rate',
      yoy_rate: 'YOY Rate',
      available_rooms: 'Available Rooms',
      available_cap: 'Available Capacity',
      group_booked: 'Group Booked',
      group_blocked: 'Group Blocked',
      transient_booked: 'Transient Booked',
      overbooking: 'Overbooking',
      out_of_order: 'Out-of-Order',
      total_capacity: 'Total Capacity',
    },
    upload_rates_confirmation: 'Rate upload successfully triggered',
    upload_single_rate_modal: {
      header: 'Rate Upload Confirmation',
      message: 'Are you sure you want to upload the recommended rate?',
    },
    reset_rates_modal: {
      header: 'Warning - Resetting Rates',
      message:
        'This will cause your rate(s) to reset to the original rate recommendation. Are you sure you want to continue?',
    },
    rate_shop_modal: {
      header: 'Customize Rate Shop Details',
      rate_type: 'Rate Type',
      los: 'LOS',
      channel: 'Channel',
      room_type: 'Room Type',
      change_days: 'Change Days',
    },
    search: {
      search_names: {
        default: 'All days - Next 120 days',
        alert: 'Alerts',
        rec_price_change: 'Rec Price Change - Next 90 days',
      },
      search_name_placeholder: 'Search Name',
      save_search_msg: 'Search saved successfully',
      delete_search_msg_success: 'Search deleted successfully',
      delete_search_msg_error: 'Search failed to delete',
      copy: 'Copy of ',
      save: 'Save',
      continue: 'Continue',
      confirmation: 'Confirmation',
      yes: 'Yes',
      no: 'No',
      delete_search_msg: 'Would you like to delete your search?',
      discard_search_msg: 'Would you like to discard your search?',
      btn_save_as: 'Save as',
      btn_save: 'Save',
      btn_reset: 'Reset',
      btn_search: 'Search',
      btn_create_new_search: 'Create New Search',
      btn_add_search_criteria: 'Add Search Criteria',
      label_days: 'days',
      search_rhs_value: 'Value',
      form_error: {
        required_search_name: 'Enter Search name',
        required_day_err: 'At least one day is required',
        required_status_err: 'Select at least one status',
        search_name_copy_err:
          'Please use different search name to copy this search',
        search_name_exist_err: 'Search name exists already; please try another',
        max_search_name_err: 'Search name should not exceed 50 characters',
      },
      tooltip: {
        create_search: 'Create New Search',
        reset: 'Reset',
        delete_search: 'Delete Search',
        manage_search: 'Manage Search',
      },
    },
  },
  rules: {
    seasons: 'Seasons',
    competitors: 'Competitors',
    rates: 'Rates',
    autopilot: 'Autopilot',
    default_season_name: DEFAULT_SEASON_NAME,
    modify_confirmation_msg: 'Season successfully updated!',
    modify_error_msg: 'Season update failed',
    create_confirmation_msg: 'Season created successfully',
    create_error_msg: 'Season creation failed',
    copy: 'Copy of ',
    continue: 'Continue',
    finish: 'Finish',
    back: 'Back',
    confirmation: 'Confirmation',
    ok: 'Ok',
    overlap_confirmation_message:
      'This season conflicts with the below season(s). Please adjust the priorities here to resolve the conflict.',
    confirmation_message:
      'Would you like to discard your changes and create a new season?',
    current: 'Current',
    priority: 'Priority',
    new: 'New',
    competitor_table: {
      name: 'Name',
      address: 'Address',
      city: 'City',
      state: 'State',
      zip: 'Zip',
      country: 'Country',
      rating: 'Rating',
      review: 'No. of Reviews',
      weighting_percentage: 'Weighting %',
      total: 'Total',
      save_success_msg: 'Changes have been successfully saved',
      save_failed_err: 'Failed to save changes',
      season_not_selected_err: 'Please select a season to save competitor set',
      no_competitors_error: 'Seasons must have at least one competitor',
      total_weighting_error: 'Weightings should total 100%',
    },
    no_prop_select_err: 'Please select a property to create a season',
    already_exist_err:
      'This season already exists.  Please provide a new season name and/or start date.',
    autopilot_tab: {
      threshold_high_err: 'Max value should be greater than Min value.',
      positive_only: 'Positive only',
      min: 'Min',
      max: 'Max',
      high: 'High',
      med: 'Med',
      low: 'Low',
      info: {
        header_1: 'How can Autopilot help you?',
        paragraph_1:
          'It can automatically send price recommendations to the Reservation system based on your threshold definition',
        header_2: 'Increased RevPAR',
        paragraph_2:
          'Hotels that autopilot see an increased RevPAR growth year-over-year compared to non-autopilot hotels',
      },
      save_success_msg: 'Autopilot settings saved successfully',
    },
  },
  events: {
    event_name: 'Event Name',
    event_type: 'Event Type',
    name: 'Name',
    start_date: 'Start Date',
    end_date: 'End Date',
    occupancy_impact: 'Occupancy Impact',
    mul_copy_err: 'Only one event can be copied at a time.',
    noselect_copy_err: 'Please select a event to copy.',
    modify_confirmation_msg: 'Event successfully updated!',
    modify_error_msg: 'Event update failed',
    create_confirmation_msg: 'Event created successfully',
    create_error_msg: 'Event creation failed',
    active: 'Active',
    past: 'Past',
    new_event: 'New Event',
    ascending: 'Asc',
    descending: 'Desc',
    noselect_delete_err: 'Please select at least one event to delete.',
    past_event_edit_err: 'Past event cannot be edited.',
    delete_single_success_msg: 'event has been deleted!',
    delete_multiple_success_msg: 'events have been deleted!',
    delete_event_record_success_msg: 'event record has been deleted!',
    confirmation_message:
      'Are you sure you want to delete the below events? \n',
    modal_header_text: 'Confirmation',
    add_dates_text: 'Add Dates',
    validation: {
      required: 'Required',
      start_date_err: '"Start Date" cannot be in the past',
      end_date_err: '"End date" should be greater than or equal to start date',
    },
    tooltip: {
      add_event_record: 'Add event occurrence',
      remove_event_record: 'Remove event occurrence',
    },
  },
  season_list: {
    active: 'Active',
    past: 'Past',
    mul_copy_err: 'Only one season can be copied at a time.',
    noselect_delete_err: 'Please select at least one season to delete.',
    past_season_edit_err: 'Past season cannot be edited.',
    delete_single_success_msg: 'season has been deleted!',
    delete_multiple_success_msg: 'seasons have been deleted!',
    modal_header_text: 'Confirmation',
    confirmation_message:
      'Are you sure you want to delete the below seasons? \n',
    name: 'Name',
    start_date: 'Start Date',
    ascending: 'Asc',
    descending: 'Desc',
    new_season: 'New Season',
  },
  settings: {
    rounding_rules: {
      show_rules: 'Show Rounding Rules',
      column_headers: {
        rule_id: 'Rule ID',
        price_bound: 'Price Bound',
        description: 'Description',
        format: 'Rounding Format',
        format_above: 'Format Above Bound',
        method: 'Rounding Method',
        use_bound: 'Use Price Bound',
      },
    },
  },
  alerts: {
    propertyAccess:
      'Property access denied. Please select a different property',
    insufficientRole: 'Insufficient role to perform this action',
  },
  chat: {
    title: 'RevBot',
    minimize: 'Minimize Chat',
    close: 'Close Chat',
    send: 'Send',
    placeholder: 'Type your message here...',
    error: 'Sorry, I am unable to process your request at the moment.',
  },
  group_pricing: {
    net_benefit_error: 'Error fetching net benefit data',
    room_availability_error: 'Error fetching room availability data',
  },
};
