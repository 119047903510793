import dayjs, { Dayjs, ManipulateType } from 'dayjs';

import { Maybe } from 'types/maybe-type';
import _ from 'lodash';
import advanced from 'dayjs/plugin/advancedFormat';
import isBetween from 'dayjs/plugin/isBetween';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(isBetween);
dayjs.extend(localizedFormat);
dayjs.extend(timezone);
dayjs.extend(advanced);

const tz = 'America/Los_Angeles';

export const defaultPriorDays = 7;

// The format for most dates within data sources
export const dateFormatData = 'YYYY-MM-DD';

// The format for dates in the UI shown to the user
export const dateFormatDisplay = 'M/D/YYYY';

// The format for dates shown in Home dashboard
export const dateFormatHomeDisplay = 'D MMM, ddd';

export const timeStampMinuteFormat = 'YYYY-MM-DDTHH:mm';

export const isDuringDataProcessing = () => {
  const todayAt4am = dayjs()
    .set('hour', 4)
    .set('minute', 0)
    .set('second', 0)
    .set('millisecond', 0);

  const todayAt6am = dayjs()
    .set('hour', 6)
    .set('minute', 0)
    .set('second', 0)
    .set('millisecond', 0);

  return dayjs().isBetween(todayAt4am, todayAt6am, 'minutes', '[]');
};

/**
 * Format a date string for data sources
 * @param date A string date
 * @returns A string date in the format YYYY-MM-DD
 */
export const dataDate = (date?: Maybe<string>): string => {
  if (date?.includes('T00:00:00.000Z')) {
    return dayjs.utc(date).format(dateFormatData);
  } else {
    return dayjs(date).format(dateFormatData);
  }
};

export const timeStampMinute = (date?: Maybe<string>): string => {
  return dayjs(date).format(timeStampMinuteFormat);
};

/**
 * Format a date string
 * @param date A string date, format: YYYY-MM-DD
 * @param format A string format, default: M/D/YYYY
 * @returns A string for the date, format: M/D/YYYY.
 * @example formatDate('2021-01-01') // 1/1/2021
 * @example formatDate('2021-01-01', 'YYYY-MM-DD') // 2021-01-01
 */
export const formatDate = (
  date?: Maybe<string>,
  format = dateFormatDisplay
): string => {
  if (typeof date === 'string' && date?.includes('T00:00:00.000Z')) {
    return dayjs.utc(date).format(format);
  } else {
    return dayjs(date).format(format);
  }
};

export const formatFormDate = (
  date?: Maybe<string>,
  format = dateFormatDisplay
) => {
  return dayjs.tz(date, tz).format(format);
};

export const formatUpdatedAt = (
  date?: Maybe<string>,
  format = dateFormatDisplay
) => {
  return dayjs(date).tz(tz).format(format);
};

export const fromToday = (days = 0) => {
  return dayjs().tz(tz).add(days, 'day').format(dateFormatData);
};

export const addDays = (days: number, date: Dayjs | string = dayjs()) => {
  return dayjs(date).add(days, 'day');
};

export const todayAdd = (number: number, unit: ManipulateType) => {
  return dayjs(today()).add(number, unit).format(dateFormatData);
};

export const todaySubtract = (number: number, unit: ManipulateType) => {
  return dayjs(today()).subtract(number, unit).format(dateFormatData);
};

/**
 * Add Days to Date, return as string
 * @param days a number of days to add
 * @param date the starting date, in string or Dayjs format. Defaults to dayjs()
 * @returns a date formatted as YYYY-MM-DD
 */
export const addDaysStr = (days: number, date: Dayjs | string = dayjs()) => {
  return dayjs(date).add(days, 'day').format(dateFormatData);
};

/**
 * Get the Year of a date string
 * @param date A string date, format: YYYY-MM-DD
 * @returns A string for the year, format: YYYY.
 */
export const getYear = (date?: Maybe<string>): string => {
  return formatDate(date, 'YYYY');
};

/**
 * Today's date
 * @returns A string for the current date
 */
export const today = (): string => {
  return dayjs().tz(tz).format(dateFormatData);
};

/**
 * Days difference between two dates
 * @param firstDate A string representing the first date. Format: YYYY-MM-DD
 * @param lastDate A string representing the last date. Format: YYYY-MM-DD
 * @returns A number representing the number of days between firstDate and lastDate
 */
export const dayDiff = (firstDate: string, lastDate: string): number => {
  return Number(dayjs(lastDate).diff(dayjs(firstDate), 'day'));
};

/**
 * Generate a date range
 * @param firstDate A string representing the first date. Format: YYYY-MM-DD
 * @param lastDate A string representing the last date. Format: YYYY-MM-DD
 * @returns An array of string dates between and including firstDate and lastDate
 */
export const dayRange = (firstDate: string, lastDate: string): string[] => {
  const days: string[] = [];
  for (let i = 0; i <= dayDiff(firstDate, lastDate); i++) {
    days.push(dayjs(firstDate).add(i, 'day').format(dateFormatData));
  }
  const uniqueDays = _.uniq(days);
  return sortByStayDate(uniqueDays) as string[];
};

/**
 * Generate a date range for use with seasons, where the start and end dates can be extremely far apart
 * @param seasonStart a string representing the first date. Format: YYYY-MM-DD
 * @param seasonEnd a string representing the last date. Format: YYYY-MM-DD
 * @param smartLimits optional boolean to limit the number of days returned, default true
 * @returns An array of string dates between and including seasonStart and seasonEnd
 */
export const seasonDayRange = (
  seasonStart: string,
  seasonEnd: string,
  smartLimits = true
) => {
  const SMART_LIMIT = 400;
  const days: string[] = [];

  if (smartLimits) {
    if (dayDiff(seasonStart, seasonEnd) > SMART_LIMIT) {
      // create smart start date that is one week before today
      const smartStartDate = fromToday(-7);
      // create smart end date that is either the end of the season or one year from today, whichever is sooner
      const smartEndDate = dayjs().isAfter(dayjs(seasonEnd))
        ? seasonEnd
        : fromToday(365);

      for (let i = 0; i <= dayDiff(smartStartDate, smartEndDate); i++) {
        days.push(dayjs(smartStartDate).add(i, 'day').format(dateFormatData));
      }
    } else {
      for (let i = 0; i <= dayDiff(seasonStart, seasonEnd); i++) {
        days.push(dayjs(seasonStart).add(i, 'day').format(dateFormatData));
      }
    }
  }

  return days;
};

/**
 * Sort an array of items by a stay_date property
 * @param records can be an array of objects or an array of strings
 * @returns The array of items sorted by stay_date
 */
export const sortByStayDate = (
  records: Partial<{ stay_date: string }>[] | string[]
) => {
  return records.sort((a, b) => {
    if (typeof a === 'string' && typeof b === 'string') {
      return dayjs(a).isAfter(dayjs(b)) ? 1 : -1;
    } else if (typeof a === 'object' && typeof b === 'object') {
      return dayjs(a.stay_date).isAfter(dayjs(b.stay_date)) ? 1 : -1;
    } else {
      return 0;
    }
  });
};

export const formatDateWithGivenFormatter = (date: string, format: string) => {
  return dayjs.utc(date).format(format);
};

interface MonthsOutParams {
  date?: string | dayjs.Dayjs;
  months?: number;
  firstOrLast?: 'first' | 'last';
}

export const monthsOut = ({
  date = dayjs(),
  months = 2,
  firstOrLast = 'last',
}: MonthsOutParams): string => {
  if (typeof date === 'string') {
    date = dayjs(date);
  }
  if (firstOrLast === 'first') {
    return date.add(months, 'month').startOf('month').format(dateFormatData);
  }
  return date.add(months, 'month').endOf('month').format(dateFormatData);
};

/**
 * Get the day of the week for a date
 * @param date A string date, format: YYYY-MM-DD
 * @returns Number as day of Week (Sunday as 0, Saturday as 6)
 */
export const getDayOfWeek = (date?: Maybe<string>): number => {
  return dayjs.utc(date).get('day');
};

/**
 * Get the day of the week for a date, formatted as 'friday', 'saturday', etc.
 * @param date A string date, format: YYYY-MM-DD
 * @returns String as day of Week (Sunday as 'sunday', Saturday as 'saturday')
 */
export const getLongDayOfWeek = (date?: Maybe<string>): string => {
  return dayjs.utc(date).format('dddd').toLowerCase();
};

export const checkDateIsPast = (date?: Maybe<string>): boolean => {
  const isPast = dayjs(date).isBefore(dayjs());
  return isPast;
};
