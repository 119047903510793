import './EventList.scss';
import { AlertMessage } from 'types/EventsTypes';
import { BiSort } from 'react-icons/bi';
import { LoadingBar } from 'components/loading-bar/loading-bar';
import { MenuButton } from 'components/MenuButton/MenuButton';
import { ToggleButton } from 'components/ToggleButton/ToggleButton';
import { formatFormDate } from 'features/dates/date-helpers';
import { useUser } from 'features/users/context/userContext';
import { labels } from 'locales/en.label';
import { FaPlus } from 'react-icons/fa';
import { today } from 'features/dates/date-helpers';
import {
  initialEventFormState,
  setSelectedEvents,
  updateEventsFormValues,
} from 'features/events/redux/events-form-slice';
import { EventResponse } from 'graphql/gql-types';
import dayjs from 'dayjs';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useEvents } from 'features/events/hooks/event-hooks';
import { orderBy, sortBy, trim } from 'lodash';

type EventListProps = {
  onError: ({ message, messageType }: AlertMessage) => void;
  //onEventCopy: (events: any, event: EventResponse) => void;
  onFormReset: () => void;
};

type EventType = 'active' | 'past';
type EventListSortOptionType = {
  label: string;
};

const eventListSortOptions: EventListSortOptionType[] = [
  { label: labels.events.name },
  { label: labels.events.start_date },
];

const sortFields = {
  [labels.events.name]: 'name',
  [labels.events.start_date]: 'start_date',
};

type SortFilter = {
  sortByIndex: number;
  sortOrder: string;
};

export const formatDataForEventForm = (events: any, evnt: any) => {
  const temp = events?.filter((e: { event_id: any }) => {
    return e.event_id === evnt.event_id;
  });

  const eventDetails = temp && {
    ...temp[0],
  };

  // Creating the result structure to support Event Form
  const result = {
    event_id: eventDetails.event_id,
    name: eventDetails.event.name,
    created_at: eventDetails.event.created_at,
    created_by_id: eventDetails.event.created_by_id,
    updated_at: eventDetails.event.updated_at,
    updated_by_id: eventDetails.event.updated_by_id,
    recurs: eventDetails.event.recurs,
    occ_impact: eventDetails.event.occ_impact,
    category: eventDetails.event.category,
    property_id: eventDetails.event.property_id,
    records: temp?.map((item: any) => ({
      id: item.id,
      start_date: item.start_date,
      end_date: item.end_date,
      event_id: eventDetails.event_id,
    })),
  };
  return result;
};

export const EventList = ({
  onError,
  //onEventCopy,
  onFormReset,
}: EventListProps) => {
  const [isPastEvent, setIsPastEvent] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { eventsList, loading } = useEvents();
  const [view, setView] = useState<EventType>('active');
  const [events, setEvents] = useState(eventsList);
  const [sortByOrder, setSortByOrder] = useState<SortFilter>({
    sortByIndex: 1,
    sortOrder: labels.events.ascending,
  });
  const selectedEvents = useAppSelector(
    (state) => state?.eventsForm.selectedEvents
  );
  const groupedEvents = useMemo(() => {
    const groupedEvents: Record<EventType, EventResponse[]> = {
      active: [],
      past: [],
    };

    if (!events) {
      return groupedEvents;
    }

    let allEvents = [...events];
    const sortField =
      sortFields[eventListSortOptions[sortByOrder.sortByIndex].label];
    if (sortField === 'name') {
      allEvents = orderBy(
        allEvents,
        (item: any) => trim(item?.event?.name).toLowerCase(),
        [sortByOrder.sortOrder as 'asc' | 'desc']
      );
    } else {
      allEvents = sortBy(allEvents, [sortField]);
    }

    if (sortByOrder.sortOrder === labels.events.descending)
      allEvents = allEvents.reverse();

    allEvents?.forEach((event) => {
      if (
        dayjs(event.end_date).isSameOrAfter(today()) ||
        event?.end_date === null
      ) {
        groupedEvents.active.push(event!);
      } else {
        groupedEvents.past.push(event!);
      }
    });

    return groupedEvents;
  }, [events, sortByOrder.sortByIndex, sortByOrder.sortOrder]);

  // select event if it's the only one checked
  useEffect(() => {
    if (eventsList) {
      setEvents(eventsList);
    }

    if (selectedEvents && selectedEvents.length === 1) {
      const checkedEvent = formatDataForEventForm(
        groupedEvents.active,
        selectedEvents[0]
      );
      dispatch(updateEventsFormValues({ ...checkedEvent }));
    } else {
      onFormReset();
      dispatch(updateEventsFormValues(initialEventFormState.formValues));
    }
  }, [
    selectedEvents,
    dispatch,
    onFormReset,
    eventsList,
    events,
    groupedEvents.active,
  ]);

  const onToggle = (event: ChangeEvent<HTMLInputElement>) => {
    setIsPastEvent(event.target.checked);
    setView(event.target.checked ? 'past' : 'active');
  };

  const onSortByOptionChange = (newOption: SortFilter) => {
    setSortByOrder(newOption);
  };

  const onRowClick = (checked: boolean, evnt: EventResponse | null) => {
    if (isPastEvent) {
      onError({
        message: labels.events.past_event_edit_err,
        messageType: 'error',
      });
      return;
    } else {
      if (checked) {
        if (evnt) {
          selectedEvents &&
            dispatch(setSelectedEvents([...selectedEvents!, evnt]));
        } else {
          dispatch(setSelectedEvents([]));
        }
      } else {
        const index = selectedEvents?.findIndex(
          ({ event_id }) => event_id === evnt?.event_id
        );

        if (selectedEvents) {
          const test = [
            ...selectedEvents.slice(0, index),
            ...selectedEvents.slice(index! + 1),
          ];
          dispatch(setSelectedEvents(test));
        }
      }
    }
  };

  const { isReadOnly } = useUser();
  return (
    <>
      <div className='event-list'>
        <div className='event-list__header'>
          <div className='event-list__toggleBtn'>
            <ToggleButton
              id='event-type'
              selected={isPastEvent}
              toggleSelected={onToggle}
              labelOptions={[labels.events.active, labels.events.past]}
            />
          </div>
          <div className='event-list__actionBtns'>
            <MenuButton
              title='Sort By'
              options={eventListSortOptions}
              selectedOption={sortByOrder}
              onSelectedOptionChange={onSortByOptionChange}
            >
              <BiSort className='event-list__actionIcon' size={14} />
            </MenuButton>
          </div>
        </div>
        <LoadingBar className='event-list__body' loading={loading}>
          <ul className='event-list__items'>
            {groupedEvents[view]?.map((evnt) => {
              return (
                <li className='event-list__item' key={evnt?.id}>
                  <input
                    className='hidden'
                    type='checkbox'
                    id={evnt?.id}
                    name={evnt?.id}
                    checked={selectedEvents?.includes(evnt)}
                    onChange={(event) => onRowClick(event.target.checked, evnt)}
                  />
                  <label htmlFor={evnt?.id} className='event-label'>
                    <div className='event-label__name event-list__text'>
                      {evnt.event?.name}
                    </div>

                    <div className='event-label__date event-list__text'>
                      {formatFormDate(evnt?.start_date, 'DD MMM YYYY')}
                    </div>
                  </label>
                </li>
              );
            })}
            {!isPastEvent && !isReadOnly && (
              <li className='event-list__item'>
                <input
                  className='hidden'
                  type='checkbox'
                  id='newEvent'
                  name='newEvent'
                  checked={!selectedEvents?.length}
                  onChange={(event) => onRowClick(event.target.checked, null)}
                />
                <label htmlFor='newEvent' className='event-label'>
                  <div className='name event-list__text'>
                    {selectedEvents?.length! > 0 && (
                      <FaPlus className='add-icon' />
                    )}
                    {labels.events.new_event}
                  </div>
                </label>
              </li>
            )}
          </ul>
        </LoadingBar>
      </div>
    </>
  );
};
