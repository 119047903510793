import axios from 'axios';
import { windowDataExtractor } from './trackingHelper';
import { TrackingInput } from 'types/TrackingTypes';

export const datalakeIngestion = async (
  window: Window,
  trackingData: TrackingInput,
) => {
  const clientData = windowDataExtractor(window);
  const {value, ...restData} = trackingData;
  return await axios.post(
    process.env.REACT_APP_REST_API_URL! + '/data-stream',
    { ...restData, ...clientData, value: JSON.parse(value!) }
  );
};
