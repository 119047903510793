import { GET_FAV_NIGHTS, GET_PROP_METRICS } from 'queries/gqlRecReview';
import {
  PropMetrics,
  PropMetricsResponse,
  UserFavNights,
} from 'features/home/types/prop-metrics';
import { dataDate, fromToday, today } from 'features/dates/date-helpers';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  useGetIntradayForecastQuery,
  useGetPropertyRecRatesQuery,
} from 'features/rec-rates/gql/_gen_/property-rec-rates.gql';

import { Dayjs } from 'dayjs';
import { LiveData } from 'types/LiveDataTypes';
import { METRICS_DURATION } from '../helpers';
import { RecReview } from 'graphql/gql-types';
import { RecReviewStatus } from 'types/RecReviewTypes';
import { useGetLiveDataQuery } from 'features/my-view/gql/_gen_/rec-review.gql';
import { usePropertyContext } from 'context/propertyContext';
import { useQuery } from '@apollo/client';
import { useUser } from 'features/users/context/userContext';

export type MetricsWithLiveData = PropMetrics &
  Partial<LiveData> & { last_updated_date: string };

type UsePropMetricsResponse = {
  data: MetricsWithLiveData[] | undefined;
  loading: boolean;
  startOfList: boolean;
  endOfList: boolean;
  next: () => void;
  previous: () => void;
  reset: () => void;
  jumpToDate: (date: Dayjs) => void;
};

export const usePropMetrics = (
  numberOfDays: number,
  filter?: { field: string; value: any } | null,
  handleFilterChange?: (level: number | null) => void
): UsePropMetricsResponse => {
  const [startIndex, setStartIndex] = useState(0);

  const next = () => setStartIndex((start) => start + numberOfDays);
  const previous = () =>
    setStartIndex((start) => Math.max(start - numberOfDays, 0));
  const reset = () => {
    setStartIndex(0);
  };

  const jumpToIndexRef = useRef(0);
  const jumpToDate = (date: Dayjs) => {
    if (!data) return;
    const index = data.PropMetrics.results.findIndex((m) =>
      date.isSame(m.stay_date, 'day')
    );
    if (!filter) {
      setStartIndex(index);
    } else {
      jumpToIndexRef.current = index;
      handleFilterChange?.(null); // This will trigger useEffect hook below when filter changes
    }
  };

  useEffect(() => {
    // Any time filter changes, reset the startIndex to either 0 or the index determined by jumpToDate
    setStartIndex(jumpToIndexRef.current);
    jumpToIndexRef.current = 0;
  }, [filter?.field, filter?.value]);

  const { property } = usePropertyContext();
  const { user } = useUser();
  const propertyId = property?.propertyId;
  const userId = user?.id;

  const { data, loading } = useQuery<{
    PropMetrics: PropMetricsResponse;
  }>(GET_PROP_METRICS, {
    skip: !propertyId || !userId,
    variables: {
      propertyId,
      input: { userId, startDate: today(), duration: METRICS_DURATION },
    },
  });

  const { data: recRatesData } = useGetPropertyRecRatesQuery({
    skip: !propertyId,
    variables: {
      propertyId,
      dateRange: {
        startDate: today(),
        endDate: fromToday(METRICS_DURATION - 1),
      },
    },
  });

  const { data: liveRatesData } = useGetLiveDataQuery({
    skip: !propertyId,
    variables: {
      propertyId,
    },
  });

  const { data: favNightsData } = useQuery<{
    listUserFavNights: UserFavNights[];
  }>(GET_FAV_NIGHTS, {
    skip: !propertyId || !userId,
    variables: { propertyId, userId },
  });

  const { data: intradayForecastData } = useGetIntradayForecastQuery({
    skip: !propertyId,
    variables: { propertyId: propertyId! },
  });

  const filteredMetrics = useMemo(() => {
    if (!data || !favNightsData || !recRatesData) return;
    // converting to object for more efficient lookup
    const favNightsByDate: { [date: string]: boolean } = {};
    if (favNightsData.listUserFavNights.length) {
      favNightsData.listUserFavNights?.forEach((night) => {
        favNightsByDate[dataDate(night.stay_date)] = night.is_favorite;
      });
    }
    const recRatesByDate: { [date: string]: RecReview } = {};
    recRatesData?.getPropertyRecRates?.forEach((night) => {
      if (night && night.stay_date) {
        const stayDate = dataDate(night.stay_date);
        night = { ...night, stay_date: stayDate };
        recRatesByDate[stayDate] = night as RecReview;
      }
    });
    const liveRatesByDate: { [date: string]: LiveData } = {};
    liveRatesData?.getLiveData?.forEach((night) => {
      if (night && night?.stay_date) {
        const { stay_date, ...liveRates } = night;
        const stayDate = dataDate(stay_date);
        liveRatesByDate[stayDate] = liveRates as LiveData;
      }
    });

    // merging favNights, recRates, and liveData into metrics
    const metrics = data.PropMetrics.results?.map((night) => {
      const { stay_date } = night;
      const is_favorite = favNightsByDate[stay_date] ?? false;
      const recRates = recRatesByDate[stay_date];
      const liveData = liveRatesByDate[stay_date];

      return {
        ...night,
        is_favorite,
        ...recRates,
        ...liveData,
      };
    }) as MetricsWithLiveData[];

    if (filter) {
      return metrics.filter((m: any) => {
        const { field, value } = filter;
        if (Array.isArray(value)) return value.includes(m[field]);
        return m[field] === value;
      });
    }

    return metrics;
  }, [data, favNightsData, recRatesData, liveRatesData, filter]);

  const intradayRecRate =
    intradayForecastData?.getIntradayForecast?.[0]?.rec_rate;
  const doaData = filteredMetrics?.[0];
  if (intradayRecRate && doaData?.stay_date === today()) {
    if (doaData.rec_status === RecReviewStatus.REVIEW) {
      doaData.rec_rate = intradayRecRate;
    }
  }

  const endIndex = startIndex + numberOfDays;

  return {
    data: filteredMetrics,
    loading: loading,
    startOfList: startIndex === 0,
    endOfList: filteredMetrics ? filteredMetrics.length <= endIndex : true,
    next,
    previous,
    reset,
    jumpToDate,
  };
};
