import { datalakeIngestion } from 'helpers/datalakeHelper';
import { selectProperty } from '../features/property/redux/selectors';
import { useAppSelector } from 'redux/hooks';
import { useLogTrackingMutation } from 'features/tracking/gql/_gen_/tracking.gql';
import { useUser } from '../features/users/context/userContext';
import { TrackingInput } from '../types/TrackingTypes';

enum TrackingEnum {
  DATA_LAKE = 'DATA_LAKE',
  PG_DB = 'PG_DB',
}

export const useAppTracking = () => {
  const { user } = useUser();
  const property: { propertyId: string } = useAppSelector(selectProperty);
  const [logTracking] = useLogTrackingMutation();

  const getUserFields = () => {
    if (!user?.email || !user?.id || !property?.propertyId) return null;
    return {
      oktaId: user?.okta_id,
      userName: user?.name,
      userEmail: user?.email,
      userRole: user?.role,
      userId: user.id,
    };
  };

  const track = (payload: TrackingInput, trackingType: string = 'PG_DB') => {
    const userFields = getUserFields();
    if (userFields) {
      const tracking = {
        ...userFields,
        ...payload,
        propertyId: payload.propertyId || property?.propertyId,
      };

      if (trackingType === TrackingEnum.PG_DB)
        logTracking({
          variables: { tracking },
        });

      datalakeIngestion(window, tracking)
        .then()
        .catch((error) => console.log(error));
    }
  };

  return {
    track,
  };
};
