import { Box, Slider, Tooltip, Typography } from '@mui/material';
import { UseFormResetField, UseFormSetValue } from 'react-hook-form';

import { ControllerRenderProps } from 'react-hook-form';
import { DropdownList } from 'components/DropdownList/DropdownList';
import { GroupPriceToolInput } from './group-price-tool';
import InputAdornment from '@mui/material/InputAdornment';
import { MdMoreVert } from 'react-icons/md';
import React from 'react';
import TextField from '@mui/material/TextField';
import { isArray } from 'lodash';

enum FieldName {
  checkInDate = 'checkInDate',
  checkOutDate = 'checkOutDate',
  roomsRequested = 'roomsRequested',
  rateRequested = 'rateRequested',
  commission = 'commission',
  direct_var_opex = 'direct_var_opex',
  wash_factor = 'wash_factor',
  rebate = 'rebate',
  profit_margin = 'profit_margin',
  f_and_b_profit = 'f_and_b_profit',
  meeting_space_benefit = 'meeting_space_benefit',
  comp_rooms = 'comp_rooms',
  occ_fcst_override = 'occ_fcst_override',
  adr_fcst_override = 'adr_fcst_override',
  planners_adv_points = 'planners_adv_points',
  avg_stay_length = 'avg_stay_length',
}

type FieldNameType = keyof typeof FieldName;

export type FieldType = ControllerRenderProps<
  GroupPriceToolInput,
  FieldNameType
>;

interface SliderInputProps {
  label: string;
  field: ControllerRenderProps<GroupPriceToolInput, FieldNameType>;
  min: number;
  max: number;
  metric?: string;
  step?: number;
  setValue: UseFormSetValue<GroupPriceToolInput>;
  resetField: UseFormResetField<GroupPriceToolInput>;
}

export default function SliderInput({
  label,
  field,
  min,
  max,
  metric,
  step,
  setValue,
  resetField,
}: SliderInputProps) {
  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    if (!isArray(newValue)) {
      setValue(field.name, newValue);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setValue(field.name, newValue);
  };

  const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value === '') {
      resetField(field.name);
    }
  };

  return (
    <Box>
      <div className='slider-label'>
        <Tooltip title={label} placement='top' arrow>
          <Typography id='discrete-slider' noWrap>
            {label}
          </Typography>
        </Tooltip>
        <TextField
          {...field}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          InputProps={{
            startAdornment: metric === '$' && (
              <InputAdornment position='start'>{metric}</InputAdornment>
            ),
            endAdornment: (
              <div className='end-adornment'>
                {metric === '%' && (
                  <InputAdornment position='end'>{metric}</InputAdornment>
                )}
                <DropdownList
                  content={<MdMoreVert color='gray' />}
                  menu={[
                    {
                      id: 1,
                      title: 'Reset',
                      onClick: () => resetField(field.name),
                    },
                  ]}
                />
              </div>
            ),
          }}
        />
      </div>
      <Slider
        {...field}
        aria-labelledby='discrete-slider'
        valueLabelDisplay='auto'
        min={min}
        max={max}
        step={step || 1}
        value={
          typeof field.value === 'string' ? Number(field.value) : field.value
        }
        onChange={handleSliderChange}
      />
    </Box>
  );
}
